<script setup>
	//	Builder
	import { RenderContent } from '@builder.io/sdk-vue/vue3';

	// Composables
	import { getComponents } from '@/composables/builder';
	import { makeHash } from '@/composables/helpers/makeHash';

	// Props
	const props = defineProps({
		page: {
			type: Object,
			required: true,
		},
	});

	const { page } = toRefs(props);
</script>

<template>
	<div class="content-wrapper">
		<RenderContent
			:content="page.content"
			:model="page.model"
			api-version="v3"
			:api-key="page.api"
			:customComponents="getComponents(page.api)"
			:key="makeHash(JSON.stringify(page.content))"
		/>
	</div>
</template>
